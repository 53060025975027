import { useEffect, useRef, useState, useCallback } from 'react';
import * as Sentry from '@sentry/nextjs';
import { createWrappedError } from '@/utils/errors';
import logger from '@/utils/logger';
import { useAuth } from '@/hooks/useAuth';
import { useToast } from '@/hooks/useToast';
import * as errorUtils from './errors';
const PREFIX = 'features: Auth: hooks: useGoogleOneTap:';
const NOTIFICATION_ERROR_MESSAGES = {
  browser_not_supported: 'Browser not supported',
  secure_http_required: 'Please use a secure connection',
  unknown_reason: 'Something went wrong. Please try again',
  issuing_failed: 'Failed to issue credential'
};
export default function useGoogleOneTap(_ref) {
  let {
    authContext = 'signin',
    onCredentialResponse,
    onError
  } = _ref;
  const {
    user
  } = useAuth();
  const {
    addToast: sendNotification
  } = useToast();
  const {
    0: isGoogleScriptLoaded,
    1: setIsGoogleScriptLoaded
  } = useState(false);
  const {
    0: isOneTapPromptDisplayed,
    1: setOneTapPromptDisplayed
  } = useState(false);
  const isGoogleOneTapInitialized = useRef(false);
  const cancelGoogleOneTapRef = useRef(undefined);
  const handleErrorResponse = useCallback(reason => {
    const errorMessage = NOTIFICATION_ERROR_MESSAGES[reason];
    if (!errorMessage) return;
    const err = errorUtils.createGoogleOneTapError(PREFIX, new Error(errorMessage));
    const wrappedError = createWrappedError(`${PREFIX} handleErrorResponse: Google One Tap failed:`, err);
    logger.error(wrappedError);
    Sentry.captureException(wrappedError);
    sendNotification({
      type: 'error',
      description: err.userMessage || 'Unexpected issue, please try again.'
    });
    typeof onError === 'function' && onError(err);
  }, [onError, sendNotification]);
  const showOneTapPrompt = useCallback(fallbackFn => {
    window.google.accounts.id.prompt(notification => {
      if (notification.isDisplayed()) {
        setOneTapPromptDisplayed(true);
        return;
      }

      if (notification.isNotDisplayed()) {
        logger.info('One Tap was not displayed:', notification.getNotDisplayedReason());
        handleErrorResponse(notification.getNotDisplayedReason());
      }

      const skippedReason = notification.getSkippedReason();

      if (notification.isSkippedMoment()) {
        logger.info('One Tap was skipped:', skippedReason);
        handleErrorResponse(notification.getSkippedReason());
      }

      if (!['user_cancel', 'tap_outside'].includes(skippedReason) && !notification.isDismissedMoment()) {
        typeof fallbackFn === 'function' && fallbackFn();
      }

      setOneTapPromptDisplayed(false);
    });
  }, [handleErrorResponse]);
  const initializeGoogleOneTap = useCallback(() => {
    if (isGoogleOneTapInitialized.current || !isGoogleScriptLoaded) return;

    if (window.google) {
      try {
        isGoogleOneTapInitialized.current = true;
        window.google.accounts.id.initialize({
          client_id: process.env.NEXT_PUBLIC_GOOGLE_OAUTH2_CLIENT_ID || '',
          callback: onCredentialResponse,
          context: authContext,
          ux_mode: 'popup',
          auto_select: false,
          cancel_on_tap_outside: true,
          use_fedcm_for_prompt: false
        }); // Setup cleanup function

        cancelGoogleOneTapRef.current = () => {
          var _window$google;

          (_window$google = window.google) === null || _window$google === void 0 ? void 0 : _window$google.accounts.id.cancel();
        };
      } catch (error) {
        if (error instanceof Error && error.message.includes('Only one navigator.credentials.get request may be outstanding at one time')) {
          logger.info('FedCM request already in progress. Waiting before retrying...');
          setTimeout(initializeGoogleOneTap, 1000);
        } else {
          logger.error('Error initializing Google One Tap:', error);
          handleErrorResponse('unknown_reason');
        }
      }
    }
  }, [authContext, isGoogleScriptLoaded, onCredentialResponse, handleErrorResponse]);
  useEffect(() => {
    initializeGoogleOneTap();
  }, [initializeGoogleOneTap]); // If user is signed in, cancel any ongoing One Tap prompts

  useEffect(() => {
    if (user) {
      var _cancelGoogleOneTapRe;

      (_cancelGoogleOneTapRe = cancelGoogleOneTapRef.current) === null || _cancelGoogleOneTapRe === void 0 ? void 0 : _cancelGoogleOneTapRe.call(cancelGoogleOneTapRef);
    }
  }, [user]); // Load Google One Tap script

  useEffect(() => {
    const script = document.createElement('script');
    script.src = 'https://accounts.google.com/gsi/client';
    script.async = true;
    script.defer = true;

    script.onload = () => {
      setIsGoogleScriptLoaded(true);
    };

    if (!document.querySelector('script[src*="accounts.google.com/gsi/client"]')) {
      document.body.appendChild(script);
    } else {
      setIsGoogleScriptLoaded(true);
    }

    return () => {
      var _cancelGoogleOneTapRe2;

      const existingScript = document.querySelector('script[src*="accounts.google.com/gsi/client"]');

      if (existingScript) {
        document.body.removeChild(existingScript);
      }

      (_cancelGoogleOneTapRe2 = cancelGoogleOneTapRef.current) === null || _cancelGoogleOneTapRe2 === void 0 ? void 0 : _cancelGoogleOneTapRe2.call(cancelGoogleOneTapRef); // Cancel on unmount

      setIsGoogleScriptLoaded(false);
      isGoogleOneTapInitialized.current = false;
    };
  }, []);
  return {
    isOneTapPromptDisplayed,
    showOneTapPrompt
  };
}