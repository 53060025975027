import UserActionError from '@/interfaces/errors/userAction';
import ErrorBadRequest from '@/interfaces/errors/badRequest';
import ErrorForbidden from '@/interfaces/errors/forbidden';
const PREFIX = 'features: Auth: hooks: errors:';
export const CODES = {
  userRecordCreate: `${PREFIX}UserRecordCreate`,
  signIn: `${PREFIX}SignIn`,
  emailVerification: `${PREFIX}EmailVerification`,
  accountExists: `${PREFIX}AccountExists`,
  newsletterSubscribe: `${PREFIX}NewsletterSubscribe`,
  popupClosedByUser: `${PREFIX}PopupClosedByUser`
};
const USER_CREATE_MESSAGES = {
  badRequest: 'Sign up request failed, please correct any issues and try again',
  unpermissioned: 'You do not have permission to join the requested team, please check with team owner or contact support',
  generic: 'Sorry the sign up failed due to unforseen issues, please try again or contact chat support',
  accountExists: 'Looks like your account already exists, please login'
};
export const createUserRecordError = (prefix, err) => {
  const actionErr = new UserActionError(`${prefix} failed to create user firestore record: ${err}`);
  actionErr.userMessage = getUserCreateErrorMessage(err) || 'Unexpected system failure, please try again or contact support.';
  actionErr.code = CODES.userRecordCreate;
  return actionErr;
};
export const createSignInError = (prefix, err) => {
  const actionErr = new UserActionError(`${prefix} failed to send email verification: ${err}`);
  actionErr.userMessage = 'Failed to sign in automatically, please proceed to login or contact chat support';
  actionErr.code = CODES.signIn;
  return actionErr;
};
export const createEmailVerificationError = (prefix, err) => {
  const actionErr = new UserActionError(`${prefix} failed to send email verification: ${err}`);
  actionErr.userMessage = 'Failed to send email verification, please visit your account page or contact chat support';
  actionErr.code = CODES.emailVerification;
  return actionErr;
};
export const createSignUpWithGoogleError = (prefix, err) => {
  const actionErr = new UserActionError(`${prefix} failed to sign up modal: ${err}`);
  actionErr.userMessage = err.message;

  if ('code' in err && err.code === 'auth/popup-closed-by-user') {
    actionErr.code = CODES.popupClosedByUser;
  } else {
    actionErr.code = CODES.userRecordCreate;
  }

  return actionErr;
};
export const createGoogleOneTapError = (prefix, err) => {
  const actionErr = new UserActionError(`${prefix} failed to authenticate with Google One Tap: ${err}`);
  actionErr.userMessage = err.message;
  actionErr.code = CODES.userRecordCreate;
  return actionErr;
};
export const createAccountExistsError = (prefix, err) => {
  const actionErr = new UserActionError(`${prefix} sign up failed because user exists: ${err}`);
  actionErr.userMessage = USER_CREATE_MESSAGES.accountExists;
  actionErr.code = CODES.accountExists;
  return actionErr;
};
export const createNewsletterSubscribeError = (prefix, err) => {
  const actionErr = new UserActionError(`${prefix} failed to subscribe to newsletter: ${err}`);
  actionErr.userMessage = 'Failed to subscribe to newsletter, please try again or contact chat support';
  actionErr.code = CODES.newsletterSubscribe;
  return actionErr;
}; // Check error details for account already existing error

export const hasExistingAccountError = err => {
  // @ts-ignore
  const details = (err.errors || []).map(_ref => {
    let {
      detail
    } = _ref;
    return detail || '';
  });
  return details.some(detail => detail.search('already exists') > -1);
};

const getUserCreateErrorMessage = error => {
  let description = '';

  if (error instanceof ErrorBadRequest) {
    description = USER_CREATE_MESSAGES.badRequest;
  } else if (error instanceof ErrorForbidden) {
    description = USER_CREATE_MESSAGES.unpermissioned;
  } else {
    description = USER_CREATE_MESSAGES.generic;
  }

  return description;
};