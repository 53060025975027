export default {
  multiSheetWorkbooks: true,
  chartsNavigation: true,
  hideQuestionRules: process.env.NEXT_PUBLIC_SUPPORT_HIDE_QUESTION_RULES === 'true',
  googleAuthentication: process.env.NEXT_PUBLIC_SUPPORT_GOOGLE_AUTH === 'true',
  googlePicker: process.env.NEXT_PUBLIC_SUPPORT_GOOGLE_PICKER === 'true',
  googleDriveSync: process.env.NEXT_PUBLIC_SUPPORT_GOOGLE_DRIVE_SYNC === 'true',
  sharedWorkbooksSelect: process.env.NEXT_PUBLIC_SUPPORT_SHARED_WORKBOOKS_SELECT === 'true',
  widgetEditorThemeSelect: process.env.NEXT_PUBLIC_SUPPORT_WIDGET_EDITOR_THEMES_SELECT === 'true',
  publicViewLink: process.env.NEXT_PUBLIC_SUPPORT_PUBLIC_VIEW_LINK === 'true',
  aiExperiment: process.env.NEXT_PUBLIC_SUPPORT_AI_EXPERIMENT === 'true'
};