export default function getQueryParams() {
  let fallback = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};

  if (true) {
    const result = {};
    const url = new URL(window.location.href);
    const params = new URLSearchParams(url.search); // @ts-ignore

    for (const pair of params.entries()) {
      result[pair[0]] = pair[1];
    }

    return result;
  }

  return fallback;
}